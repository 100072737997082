import { CookiesProvider } from 'react-cookie'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import App from './App/App'
import { Web3Providers } from './App/web3-providers'
import { store } from './Redux/store'

import './index.module.scss'

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <CookiesProvider>
        <Web3Providers>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </Web3Providers>
      </CookiesProvider>
    </BrowserRouter>
  </Provider>,
)
